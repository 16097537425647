import React from 'react';
import EventRegister from '../EventRegister';
import { fetchEvents } from '../../redux-actions/actionsEvents';
import NewsfeedUtil from '../../utils/NewsfeedUtil';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const LayerViewEventRegister: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { bgSegment1, sEventId } = useParams();
  const { activeEventModel } = useAppSelector(({ eventsState }) => eventsState);

  if (!activeEventModel.canRegister) {
    return null;
  }

  const handleOnRegister = () => {
    dispatch(fetchEvents());
    if (bgSegment1 === 'news') {
      NewsfeedUtil.loadNewsfeed();
    }
  };

  const { isAttending } = activeEventModel;

  const links: any = [];
  if (isAttending) {
    if (activeEventModel.schedule && activeEventModel.schedule.length >= 1) {
      links.push({
        url: `/${bgSegment1}/event/${sEventId}/schedule`,
        label: 'Schedule of events',
      });
    }
    if (activeEventModel.venue) {
      links.push({
        url: `/${bgSegment1}/event/${sEventId}/location`,
        label: 'Venue',
      });
    }
  }

  const copyText = isAttending ? activeEventModel.successText : activeEventModel.introText;

  return (
    <EventRegister
      copyText={copyText}
      links={links}
      isRegistered={isAttending}
      eventId={sEventId}
      onRegister={() => handleOnRegister()}
      onClickLink={(e, path) => {
        e?.preventDefault();
        navigate(path);
      }}
    />
  );
};

export default LayerViewEventRegister;
