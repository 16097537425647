import axios from 'axios';
import { apiUrl } from '../app/api';
import EventModel from '../models/EventModel';
import storeModel from '../models/storeModel';
import { handleErrorResponse } from '../redux-actions/actionsApp';
import { updateActiveEvent } from '../redux-actions/actionsEvents';
import AppUtil from './AppUtil';

class EventsUtil {
  static async loadSingleEvent(eventId) {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/events/${eventId}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      const activeEvent = response.data.data;
      const activeEventModel = new EventModel(activeEvent);
      storeModel.dispatch(updateActiveEvent(activeEvent, activeEventModel));
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }

  static async attendEvent(eventId, isAttending) {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      if (isAttending) {
        await axios({
          method: 'delete',
          url: `${apiUrl}/events/${eventId}/attend`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } else if (!isAttending) {
        await axios({
          method: 'post',
          url: `${apiUrl}/events/${eventId}/attend`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      }

      const updatedEvent = { ...storeModel.state.eventsState.activeEventModel.data, isAttending: !isAttending };
      storeModel.dispatch(updateActiveEvent(updatedEvent, new EventModel(updatedEvent)));
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }

  static formatSchedule(inputSchedule) {
    let scheduleArray = [];

    inputSchedule.map((item) => {
      if (item.date) {
        if (scheduleArray.length === 0) {
          scheduleArray.push({
            date: AppUtil.getFormattedDate(item.date),
            schedule: [
              {
                time:
                  item.startTime && item.endTime
                    ? AppUtil.getFormattedTime(item.startTime) + ' - ' + AppUtil.getFormattedTime(item.endTime)
                    : AppUtil.getFormattedTime(item.startTime) || AppUtil.getFormattedTime(item.endTime),
                title: item.title,
                subtitle: item.subTitle,
              },
            ],
          });
        } else if (
          AppUtil.getFormattedDate(scheduleArray[scheduleArray.length - 1].date) !== AppUtil.getFormattedDate(item.date)
        ) {
          scheduleArray.push({
            date: AppUtil.getFormattedDate(item.date),
            schedule: [
              {
                time:
                  item.startTime && item.endTime
                    ? AppUtil.getFormattedTime(item.startTime) + ' - ' + AppUtil.getFormattedTime(item.endTime)
                    : AppUtil.getFormattedTime(item.startTime) || AppUtil.getFormattedTime(item.endTime),
                title: item.title,
                subtitle: item.subTitle,
              },
            ],
          });
        } else {
          scheduleArray[scheduleArray.length - 1].schedule.push({
            time:
              item.startTime && item.endTime
                ? AppUtil.getFormattedTime(item.startTime) + ' - ' + AppUtil.getFormattedTime(item.endTime)
                : AppUtil.getFormattedTime(item.startTime) || AppUtil.getFormattedTime(item.endTime),
            title: item.title,
            subtitle: item.subTitle,
          });
        }
      }
    });
    return scheduleArray;
  }
}

export default EventsUtil;
